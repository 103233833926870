import React, { Component } from 'react'
import { debounce, omit } from 'lodash'
import {
  Popup,
  FullscreenControl, GeolocateControl, ScaleControl
} from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ReactMapboxGl, {
  ZoomControl, RotationControl,
  Layer, Feature
} from 'react-mapbox-gl'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { MapboxExportControl } from '@watergis/mapbox-gl-export'

import coordinatesGeocoder from './geocoding'
import LegendContainer from '../Legend/LegendContainer'
import TabbableDocumentComponent from './Document'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@watergis/mapbox-gl-export/css/styles.css';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let mapScopeLeak = null
let hoverLayers = new Set([])
let clickLayers = new Set([])

const pointCursorOn = (e) => {
  mapScopeLeak.getCanvas().style.cursor = 'pointer'
}

const resetCursor = (e) => {
  mapScopeLeak.getCanvas().style.cursor = ''
}

const hoverPopup = new Popup({
  closeButton: false, closeOnClick: false
})

const re = /{([\w\d]*)}/g

function refSort (targetData, refData) {
    // Create an array of indices [0, 1, 2, ...N].
    var indices = Object.keys(refData);

    // Sort array of indices according to the reference data.
    indices.sort(function(indexA, indexB) {
      if (refData[indexA] < refData[indexB]) {
        return -1;
      } else if (refData[indexA] > refData[indexB]) {
        return 1;
      }
      return 0;
    });

    // Map array of indices to corresponding values of the target array.
    return indices.map(function(index) {
      return targetData[index];
    });
}

const clickHandlerClosure = (layerId, clickContent, overlays, setDocument) => {
  return (e) => {
    if (!clickContent) return;
    const documents = e.features.map((feature, idx) => {
      let content = clickContent.slice()
      // Inject any dynamic properties into content html template
      let m
      do {
        m = re.exec(content)
        if (m) {
          content = content.replace(m[0], feature.properties[m[1]])
        }
      } while (m);
      return {
        html: content,
        label: `${overlays[layerId].legendText} (${feature.properties[overlays[layerId].featureIdProp] || feature.id || (idx + 1).toLocaleString()})`
      }
    })
    setDocument(documents)
  }

}

export class ReactMap extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.hideNotice = this.hideNotice.bind(this)
    this.getCamera = this.getCamera.bind(this)
    this.addControls = this.addControls.bind(this)
    this.onStyleLoad = this.onStyleLoad.bind(this)
    this.setPaintProperty = this.setPaintProperty.bind(this)
    this.reorderMapLayer = this.reorderMapLayer.bind(this)
    this.getSortedOverlaysArray = this.getSortedOverlaysArray.bind(this)
    this.addGeocoderControl = this.addGeocoderControl.bind(this)
    this.addFullScreenControl = this.addFullScreenControl.bind(this)
    this.addGeolocateControl = this.addGeolocateControl.bind(this)
    this.addScaleControl = this.addScaleControl.bind(this)
    this.handleHoverStart = this.handleHoverStart.bind(this)
    this.handleHoverEnd = this.handleHoverEnd.bind(this)
    this.handleClickOn = this.handleClickOn.bind(this)
    this.handleClickOff = this.handleClickOff.bind(this)
    this.state = {
      ReactMapComponent: ReactMapboxGl({
        accessToken: this.props.mapApiKey
      }),
      fullscreenControl: null,
      geolocateControl: null,
      scaleControl: null,
      geocoderControl: null,
      exportControl: null,
      geocodedAddress: null,
      clickHandlers: {}, // layerId: function
      showNotice: true // TODO on receive props, new notice, set true again; and set false by default
    }
  }
  hideNotice() {
    this.props.setDocument(null)
    this.setState({showNotice: true})
  }
  addControls(_map) {
    this.addGeocoderControl(_map);
    this.addFullScreenControl();
    this.addGeolocateControl();
    this.addScaleControl();
    this.addExportControl(_map);
  }
  addGeocoderControl(_map) {
    if (!this.state.geocoderControl) {
      const geocoderControl = new MapboxGeocoder({
        ...this.props.geocoderProps,
        accessToken: this.props.mapApiKey,
        mapboxgl: _map,
        marker: false,
        localGeocoder: coordinatesGeocoder
      })
      geocoderControl.on('clear', () => this.setState({geocodedAddress: null}))
      // geocoderControl.on('results', ({results}) => console.log({results}))
      geocoderControl.on('result', ({result}) => {
        // console.log({result})
        this.setState({
          geocodedAddress: result.geometry
        })
      })
      // geocoderControl.on('loading', ({query}) => console.log({query}))
      geocoderControl.on('error', ({error}) => console.log({error}))
      mapScopeLeak.addControl(geocoderControl, 'top-right');
      this.setState({geocoderControl})
    }
  }
  addFullScreenControl() {
    if (!this.state.fullscreenControl) {
      const fullscreenControl = mapScopeLeak.addControl(new FullscreenControl(), "top-right");
      this.setState({fullscreenControl})
    }
  }
  addGeolocateControl() {
    if (!this.state.geolocateControl) {
      const geolocateControl = mapScopeLeak.addControl(new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
          timeout: 6000,
          maximumAge: 0
        },
        trackUserLocation: false,
        showAccuracyCircle: false
      }), "top-right");
      this.setState({geolocateControl})
    }
  }
  addScaleControl() {
    if (!this.state.scaleControl) {
      const scaleControl = mapScopeLeak.addControl(new ScaleControl({
        maxWidth: 100,
        unit: 'metric'
      }), 'bottom-left');
      this.setState({scaleControl})
    }
  }
  addExportControl(_map) {
    if (!this.state.exportControl) {
      const exportControl = mapScopeLeak.addControl(new MapboxExportControl({
        accessToken: this.props.mapApiKey,
        // PageSize: Size.A4,
        // PageOrientation: PageOrientation.Landscape,
        // Format: Format.PNG,
        // DPI: DPI[96],
        // Crosshair: true
      }), "top-right");
      this.setState({exportControl})
    }
  }
  handleHoverStart(layerId, hoverContent) {
    // Establish a new hover layer
    mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
    mapScopeLeak.on('mouseleave', layerId, resetCursor)
    if (hoverContent && typeof(hoverContent) === "string") {
      // Enable a hovering popup
      mapScopeLeak.on('mousemove', layerId, e => {
        const feature = e.features[0]
        const coordinates = e.lngLat
        let content = hoverContent.slice()
        let m
        do {
          m = re.exec(content)
          if (m) {
            content = content.replace(m[0], feature.properties[m[1]])
          }
        } while (m);
        hoverPopup.setLngLat(coordinates)
          .setHTML(content)
          .addTo(mapScopeLeak);
      })
      mapScopeLeak.on('mouseleave', layerId, () => {
        hoverPopup.remove()
      })
    }
  }
  handleHoverEnd(layerId) {
    mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
    mapScopeLeak.off('mouseleave', layerId, resetCursor)
  }
  handleClickOn(layerId, clickContent) {
    mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
    mapScopeLeak.on('mouseleave', layerId, resetCursor)
    if (clickContent && typeof(clickContent) === 'string') {
      const clickHandler = clickHandlerClosure(layerId, clickContent, this.props.overlays, this.props.setDocument);
      this.setState({clickHandlers: {...this.state.clickHandlers, [layerId]: clickHandler}}, () => {
        mapScopeLeak.on('click', layerId, clickHandler)
      })
    }
  }
  handleClickOff(layerId) {
    mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
    mapScopeLeak.off('mouseleave', layerId, resetCursor)
    // Remove the click handler, or they accumulate, since layer removal does
    //  not remove registered events
    const clickHandler = this.state.clickHandlers[layerId]
    mapScopeLeak.off('click', layerId, clickHandler)
    this.setState({clickHandlers: omit(this.state.clickHandlers, layerId)})
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (mapScopeLeak) {
      mapScopeLeak.resize()
      Object.keys(this.props.overlays).forEach(layerId => {
        const layoutProps = this.props.overlays[layerId].layoutProps
        Object.keys(layoutProps).forEach(layoutProp => {
          const currentValue = mapScopeLeak.getLayoutProperty(layerId, layoutProp)
          if (currentValue !== layoutProps[layoutProp]) {
            mapScopeLeak.setLayoutProperty(layerId, layoutProp, layoutProps[layoutProp])
          }
        })
        const hover = this.props.overlays[layerId].hover || false
        if (hover && !hoverLayers.has(layerId)) {
          hoverLayers.add(layerId)
          this.handleHoverStart(layerId, hover)
        } else if (!hover && hoverLayers.has(layerId)) {
          hoverLayers.delete(layerId)
          this.handleHoverEnd(layerId)
        }
        const clickContent = this.props.overlays[layerId].click || false
        if (clickContent && !clickLayers.has(layerId)) {
          clickLayers.add(layerId)
          this.handleClickOn(layerId, clickContent)
        } else if (!clickContent && clickLayers.has(layerId)) {
          clickLayers.delete(layerId)
          this.handleClickOff(layerId)
        }
      })
    } else if (this.props.documentHtml !== prevProps.documentHtml) {
      this.setState({showNotice: true})
    } else if (this.props.graphData !== prevProps.graphData) {
      this.setState({showNotice: true})
    }
  }
  getCamera() {
    if (!mapScopeLeak) return {}
    let {lng, lat } = mapScopeLeak.getCenter()
    let center = [lng, lat]
    let zoom = [mapScopeLeak.getZoom()]
    let bearing = mapScopeLeak.getBearing()
    let pitch = mapScopeLeak.getPitch()
    return { zoom, bearing, pitch, center }
  }
  onStyleLoad(_map) {
    if (!mapScopeLeak) {
      mapScopeLeak = _map
      this.props.styleLoaded({
        style: mapScopeLeak.getStyle()
      })
    }
    this.addControls(mapScopeLeak);
  }
  setPaintProperty(layerId, prop, value) {
    if (!mapScopeLeak) return;
    mapScopeLeak.setPaintProperty(layerId, prop, value)
    this.props.styleLoaded({
      style: mapScopeLeak.getStyle()
    });
  }
  reorderMapLayer({oldIndex, newIndex}) {
    if (!mapScopeLeak) return;
    const style = mapScopeLeak.getStyle();
    const sortedOverlays = this.getSortedOverlaysArray()
    const movingLayerId = sortedOverlays[oldIndex].id
    const offset = oldIndex > newIndex ? 1 : 0 // i.e. moving up or down the stack
    const beforeLayerId = style.layers[style.layers.findIndex(layer => layer.id === sortedOverlays[newIndex].id) + offset].id
    mapScopeLeak.moveLayer(movingLayerId, beforeLayerId);
    this.props.styleLoaded({
      style: mapScopeLeak.getStyle()
    });
  }
  getSortedOverlaysArray() {
    if (!mapScopeLeak) return;
    const style = mapScopeLeak.getStyle();
    const overlayIds = Object.keys(this.props.overlays)
    const overlayZIndicies = overlayIds.map(overlayId => style.layers.findIndex(layer => layer.id === overlayId))
    const sortedOverlayIds = refSort(overlayIds, overlayZIndicies)
    return sortedOverlayIds.map(overlayId => this.props.overlays[overlayId]).reverse()
  }
  render() {
    const ReactMapComponent = this.state.ReactMapComponent
    const debouncedSetCamera = debounce(() => this.props.setCamera(this.getCamera(), 3000, {leading: false, trailing: true}))
    const sortedOverlays = !mapScopeLeak ? [] : this.getSortedOverlaysArray()
    return (
      <div id="map-concern-container" className="map-concern-container modal-container" ref={this.containerRef}>
        {
          LegendContainer({
            overlays: sortedOverlays,
            reorderMapLayer: this.reorderMapLayer,
            removeMapLayer: this.props.removeMapLayer,
            toggleVisibility: this.props.toggleVisibility,
            setPaintProperty: this.setPaintProperty,
            containerRef: this.containerRef
          })
        }
        <TransitionGroup component={null}>
        {
          (!!this.props.document) && (
            <CSSTransition classNames="modal-transition menu-transition" timeout={600}>
              <TabbableDocumentComponent
                key={this.props.document.length}
                closeIcon={this.props.closeIcon}
                onClose={this.hideNotice}
                documents={this.props.document}
              />
            </CSSTransition>
          )
        }
        </TransitionGroup>

        <ReactMapComponent
          onStyleLoad={this.onStyleLoad}
          style={this.props.mapStyle}
          containerStyle={{
            height: `${this.props.height}px`,
            width: `${this.props.width}px`
          }}
          injectCss={true}
          preserveDrawingBuffer={true}
          zoom={this.props.zoom}
          center={this.props.center}
          bearing={this.props.bearing}
          pitch={this.props.pitch}
          animationOptions={this.props.animationOptions}
          movingMethod={this.props.movingMethod}
          onZoomEnd={(_map, e) => { debouncedSetCamera() }}
          onBoxZoomEnd={(_map, e) => { debouncedSetCamera() }}
          onTouchEnd={(_map, e) => { debouncedSetCamera() }}
          onPitchEnd={(_map, e) => { debouncedSetCamera() }}
          onDragEnd={(_map, e) => { debouncedSetCamera() }}
          onRotateEnd={(_map, e) => { debouncedSetCamera() }}
        >
          <ZoomControl position={'top-right'}/>
          <RotationControl position={'top-right'}/>
          {this.state.geocodedAddress && (<Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-11' }}>
            <Feature coordinates={this.state.geocodedAddress.coordinates}/>
          </Layer>)}
        </ReactMapComponent>
        {this.props.children}
      </div>
    );
  }
}
