import React, { Component } from 'react'
import {
  Panel as RBPanel, PanelGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton
} from 'react-bootstrap'
import SVG from 'react-inlinesvg'

const { Title, Heading, Collapse, Body } = RBPanel

class LayerButtonGroup extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      activeLayer: this.props.layers ? this.props.layers[0].id : undefined
    }
    this.onToggleChange = this.onToggleChange.bind(this)
  }
  onToggleChange(targetLayerId) {
    const layers = []
    const changes = []
    // Remove existing active selection
    layers.push(this.state.activeLayer)
    changes.push('remove')
    const newLayer = this.props.layers.find(layer => layer.id === targetLayerId)
    if (!newLayer) return
    // Add new selection
    layers.push(newLayer)
    changes.push('add')
    this.props.changeMapLayers(layers, changes)
    this.setState({
      activeLayer: newLayer.id
    })
  }
  render() {
    if (!this.props.layers) {
      return null
    }
    return (
      <div className="layer-button-group">
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="layers"
            defaultValue={this.state.activeLayer}
            value={this.props.allActiveLayers.includes(this.state.activeLayer) ? this.state.activeLayer : null}
            onChange={this.onToggleChange}
          >
            {
              this.props.layers.map(layer => {
                const active = layer.id === this.state.activeLayer && this.props.allActiveLayers.includes(layer.id)
                return (
                  <ToggleButton
                    className={active ? 'btn-active' : ''}
                    key={layer.id}
                    value={layer.id}>
                    {
                      layer.name
                    }
                  </ToggleButton>
                )
              })
            }
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}

export class Panel extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      active: new Set([]),
      lastClicked: null
    }
  }
  hideDocument(clickedId) {
    this.props.setDocument(null)
    const diff = new Set([...this.state.active])
    diff.delete(clickedId)
    this.setState({active: diff, lastClicked: clickedId})
  }
  showDocument(section, clickedId) {
    const active = new Set([...this.state.active])
    if (this.state.lastClicked !== null && !!this.props.sections[this.state.lastClicked].document) {
      // Only one document panel can be read at a time (i.e. only one active document)
      active.delete(this.state.lastClicked)
    }
    active.add(clickedId)
    this.props.setDocument(section.document, true)
    this.setState({active, lastClicked: clickedId})
  }

  render() {
    // Difficuly onEnter/onExit logic because a panel collapse event isn't the only control of whether and which document to display - but it is one form of control
    const panels = this.props.sections.map((section, i) => {
      return (
        <RBPanel eventKey={`${i}`} key={`${i}`}>
          <div className={`panel-inner-container ${(this.state.active.has(i) ? 'active' : '')}`}>
            <Heading>
              <Title toggle>
                {section.heading}
                <SVG src={process.env.REACT_APP_BASENAME + section.icon || ''}/>
              </Title>
            </Heading>
            <Collapse
              onEnter={() => {
                if (section.layers && section.layers[0]) {
                  this.props.addMapLayer(section.layers[0])
                  const union = new Set([...this.state.active])
                  union.add(i)
                  this.setState({active: union})
                } else if (section.document) {
                  if (i === this.state.lastClicked && this.props.hasLiveDocument) {
                    this.hideDocument(i)
                  } else {
                    this.showDocument(section, i)
                  }
                }
              }}
              onExit={() => {
                if (section.document) {
                  if (i === this.state.lastClicked && this.props.hasLiveDocument) {
                    this.hideDocument(i)
                  } else {
                    this.showDocument(section, i)
                  }
                } else {
                  const diff = new Set([...this.state.active])
                  diff.delete(i)
                  this.setState({active: diff})
                }
              }}
            >
              {(section.text || section.layers) ? (<Body>
                <div dangerouslySetInnerHTML={{ __html: section.text }}></div>
                <LayerButtonGroup
                  layers={section.layers}
                  addMapLayer={this.props.addMapLayer}
                  removeMapLayer={this.props.removeMapLayer}
                  changeMapLayers={this.props.changeMapLayers}
                  allActiveLayers={this.props.activeLayers}
                />
              </Body>) : (<></>)}
            </Collapse>
          </div>
        </RBPanel>
      )
    })
    return (<PanelGroup
      activeKey={this.state.activeKey}
      className="panels-container"
      id={this.props.id || 'PanelGroup'}
    >
      {panels}
    </PanelGroup>)
  }
}
