import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';

import {
  LOGO
} from '../data/data.js'

class Splash extends Component {
  render () {
    return this.props.data ? (
      <div className="app-launcher-container">
        <Grid>
          <Row>
            <Col className="app-launcher-header" xs={6} xsOffset={3}>
              <img src={process.env.REACT_APP_BASENAME + LOGO} alt="logo"/>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.SPLASH.disclaimer }} ></div>
            </Col>
          </Row>
          <Row>
            {
              this.props.data.SPLASH_APPS.map((app, i) => {
                return (
                  <Col className="app-launcher-entry-point" xs={6} key={`app-${i}`}>
                    <h2>{app.name}</h2>
                    <div dangerouslySetInnerHTML={{ __html: app.lede}} ></div>
                    <Button disabled={app.disabled} bsStyle="info" onClick={() => this.props.goTo(app.route)}>
                      {app.action}
                    </Button>
                  </Col>
                )
              })
            }
          </Row>
        </Grid>
      </div>
    ) : <div className="loading-indicator-container">Loading...</div>
  }
}

export default Splash;
