import React, { Component } from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import { Panel } from './Panel'
import * as Data from '../../data/data.js'

const midBarNav = ({onBackClick, onQuitClick}) => {
  return (<Row>
    <div className="midbar-nav">
      <div className="btn-back">
        <Button onClick={onBackClick}>
          <SVG src={process.env.REACT_APP_BASENAME + Data.BACK_ICON}/>Back
        </Button>
      </div>
      <div className="btn-quit">
        <Button onClick={onQuitClick}>
          <SVG src={process.env.REACT_APP_BASENAME + Data.CLOSE_ICON}/>
        </Button>
      </div>
    </div>
  </Row>)
}

const defaultContent = ({onBackClick, onQuitClick, instructions}) => {
  const instructionList = (instructions || {}).list ?
    instructions.list.map((li, i) => {
      return (<li key={i}>{li}</li>)
    }) : null
  return (
    <Grid className="midbar" id="__default_midbar">
      {midBarNav({onBackClick, onQuitClick})}
      <Row>
        <Col xs={12}>
          <h4>{instructions.title || null}</h4>
          <p>{instructions.summary || null}</p>
          <div className="instructions">
            <ol>
              {instructionList}
            </ol>
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export class Midbar extends Component {
  render() {
    const { midBarType, midBarData } = this.props
    if (!midBarType || !midBarData) {
      return defaultContent({
        onBackClick: this.props.onBackClick,
        onQuitClick: this.props.onQuitClick,
        instructions: this.props.instructions
      })
    } else if (midBarType === 'topic' || midBarType === 'area' || midBarType === 'members') {
      return (
        <Grid className="midbar" key={midBarData.id}>
          {midBarNav({
            onBackClick: this.props.onBackClick,
            onQuitClick: this.props.onQuitClick
          })}
          <Row>
            <Col xs={12} className="midbar-col">
              <div className="midbar-desc">
                <h3>{midBarData.name}</h3>
                <div dangerouslySetInnerHTML={{ __html: midBarData.summary }}></div>
              </div>
              {midBarData.sections && midBarData.sections.length &&
                <Panel
                  sections={midBarData.sections}
                  id={midBarData.id}
                  activeLayers={this.props.activeLayers}
                  addMapLayer={this.props.addMapLayer}
                  removeMapLayer={this.props.removeMapLayer}
                  changeMapLayers={this.props.changeMapLayers}
                  setDocument={this.props.setDocument}
                  unsetDocument={this.props.unsetDocument}
                  hasLiveDocument={this.props.hasLiveDocument}
                />
              }
            </Col>
          </Row>
          <Row>
            {midBarData.image && <div className="glass">
              <img className="primary-img" alt="category" src={process.env.REACT_APP_BASENAME + midBarData.image}/>
            </div>}
          </Row>
        </Grid>
      )
    } else {
      return defaultContent()
    }
  }
}
