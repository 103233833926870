import React from 'react'

import { propParser } from './utils'
import { LegendText } from './LegendText'

const line = (lineProps) => {
  return (<line {...lineProps}/>)
}

// Note, ignores the following properties:
// - line-offset
// - line-pattern (except that having one disables stroke-dasharray)
// - line-round-limit
// - line-sort-key
// - line-translate
// - line-translate-anchor
// - line-gradient (since it only applies to GeoJSON)
export function LineLegend(width, height, legendProps) {
  const {layer, legendText} = legendProps

  const visibility = (layer.layout['visibility'] || "visible") === 'visible' ? 'visible' : 'hidden'
  const linePattern = propParser(layer.paint['line-pattern'], null)
  const svgReactProps = {
    'strokeLinecap': propParser(layer.paint['line-cap'], 'butt'),
    'stroke': propParser(layer.paint['line-color'], '#000000', true),
    'strokeDasharray': Array.from((!linePattern ? propParser(layer.paint['line-dasharay'], null) : null) || 0).join(' ') || null,
    'strokeLinejoin': propParser(layer.paint['line-join'], 'miter'),
    'strokeMiterlimit': propParser(layer.paint['line-miter-limit'], 2),
    'opacity': propParser(layer.paint['line-opacity'], 1),
    'strokeWidth': propParser(layer.paint['line-width'], 1),
  }
  const lineGapWidth = propParser(layer.paint['line-gap-width'], 0)
  const filters = {
    'feGaussianBlur': {
      in: 'SourceGraphic',
      stdDeviation: propParser(layer.paint['line-blur'], 0)
    }
  }

  const lineProps = {
    x1:0, y1: height/3, x2: width/3, y2: height/3,
    ...svgReactProps,
  }
  let legendLine
  if (lineGapWidth === 0) {
    legendLine = line({...lineProps, transform: `rotate(25) translate(10)`})
  } else if (lineGapWidth > 0) {
    legendLine = [
      line({...lineProps, transform: `rotate(25) translate(10,${lineGapWidth/2})`}),
      line({...lineProps, transform: `rotate(25) translate(10,-${lineGapWidth/2})`}),
    ]
  }

  return (
    <div className="legend line-legend">
      <LegendText text={legendText} />
      {<svg width={width} height={height}>
        <defs>
          <filter id="filter" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur {...filters['feGaussianBlur']} />
          </filter>
        </defs>
        <g visibility={visibility}>
          {legendLine}
        </g>
      </svg>}
    </div>
  )
}
