import React, { Component } from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import ContainerDimensions from 'react-container-dimensions'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import SVG from 'react-inlinesvg'
import { isString } from 'lodash'

import { Sidebar } from './Sidebar/Sidebar'
import { Midbar } from './Midbar/Midbar'
import { ReactMap } from './Map/Map'
import { SplashModal } from './SplashModal'
import { TopBar } from './TopBar'

const MAP_WIDTH = 8
const SIDEBAR_WIDTH = 12 - MAP_WIDTH

const DEFAULT_ANIMATION_OPTIONS = {
  duration: 5000,
  offset: [0,0],
  animate: true,
  essential: false
}

const DEFAULT_MOVING_METHOD = 'flyTo'
const MOVING_METHODS = new Set(['jumpTo', 'easeTo', 'flyTo'])

// const resizeWindow = () => window.dispatchEvent(new Event('resize'));

export class MainLayout extends Component {
  constructor(props) {
    super(props)
    const ic = this.props.initialCamera
    const zoom = ic && ic.zoom ? [ic.zoom] : [9]
    const lng = ic && ic.lng ? ic.lng : 151.2093
    const lat = ic && ic.lat ? ic.lat : -33.8688
    const pitch = ic && ic.pitch ? [ic.pitch] : [0]
    const bearing = ic && ic.bearing ? [ic.bearing] : [0]

    this.state = {
      midBarData: {},
      midBarType: null,
      overlays: {},
      activeLayerIds: [],
      mapStyle: null,
      document: null,
      midBarActive: false,
      noMidBar: false,
      camera: {
        bearing, pitch, zoom, center: [lng, lat]
      },
      animationOptions: DEFAULT_ANIMATION_OPTIONS,
      movingMethod: DEFAULT_MOVING_METHOD
    }
    this.setMidBarTopic = this.setMidBarTopic.bind(this)
    this.setMidBarArea = this.setMidBarArea.bind(this)
    this.setMidBarMembers = this.setMidBarMembers.bind(this)
    this.changeMapLayers = this.changeMapLayers.bind(this)
    this.resetMidBarToDefault = this.resetMidBarToDefault.bind(this)
    this.styleLoaded = this.styleLoaded.bind(this)
    this.getLayerFromStyle = this.getLayerFromStyle.bind(this)
    this.setDocument = this.setDocument.bind(this)
    this.unsetDocument = this.unsetDocument.bind(this)
    this.unsetMidBar = this.unsetMidBar.bind(this)
    this.toggleMidBar = this.toggleMidBar.bind(this)
    this.setCamera = this.setCamera.bind(this)
    this.setAnimation = this.setAnimation.bind(this)
    this.setMovingMethod = this.setMovingMethod.bind(this)
  }
  styleLoaded({style}) {
    this.setState({
      mapStyle: style
    })
  }
  unsetMidBar() {
    this.setState({
      midBarType: null,
      midBarData: {},
      midBarActive: false
    })
  }
  toggleMidBar() {
    this.setState({
      noMidBar: !this.state.noMidBar,
      midBarActive: false
    }, () => {
      // console.log('dispatch dummy resize')
      // resizeWindow();
    })
  }
  setMidBarTopic(topic) {
    this.setState({
      midBarType: 'topic',
      midBarData: topic,
      midBarActive: true
    }, () => {
      if (topic.camera) {
        topic.camera.zoom = Array.isArray(topic.camera.zoom) ? topic.camera.zoom : [topic.camera.zoom]
        this.setCamera({...topic.camera})
      }
      if (topic.animation) {
        this.setAnimation({...topic.animation})
      }
      if (topic.movingMethod) {
        this.setMovingMethod(topic.movingMethod)
      }
    })
  }
  setMidBarArea(area) {
    this.setState({
      midBarType: 'area',
      midBarData: area,
      midBarActive: true
    })
  }
  setMidBarMembers(members) {
    this.setState({
      midBarType: 'members',
      midBarData: members,
      midBarActive: true
    })
  }
  resetMidBarToDefault() {
    this.setState({
      midBarData: {},
      midBarType: null,
      midBarActive: true
    })
  }
  setDocument(document, reset=false) {
    if (!document) {
      this.unsetDocument()
    } else {
      const documentAsArray = Array.isArray(document) ? document : [document]
      if (this.state.document && !reset) {
        this.setState({document: this.state.document.concat(documentAsArray)})
      } else {
        this.setState({document: documentAsArray})
      }
    }
  }
  unsetDocument() {
    this.setState({
      document: null
    })
  }
  getLayerFromStyle(layerId) {
    // Returns the layer matching the layerId in the map style, else undefined
    if (!layerId || !this.state.mapStyle || !this.state.mapStyle.layers || !this.state.mapStyle.layers.length) return
    return this.state.mapStyle.layers.find(l => l.id === layerId)
  }
  setCamera({pitch, bearing, zoom, center}) {
    let camera = {pitch, bearing, zoom, center}
    Object.entries(camera).forEach(([key, value]) => {
      if (!Array.isArray(value)) {
        camera[key] = [value]
      }
    })
    this.setState({camera: {
      ...this.state.camera || {},
      ...camera
    }})
  }
  setAnimation({duration, offset, animate, essential}) {
    this.setState({animationOptions: {
      ...(this.state.animation || {}),
      ...DEFAULT_ANIMATION_OPTIONS,
      duration, offset, animate, essential
    }})
  }
  setMovingMethod(movingMethod) {
    this.setState({
      movingMethod: MOVING_METHODS.has(movingMethod) ? movingMethod : DEFAULT_MOVING_METHOD
    })
  }
  changeMapLayers(layers, methods) {
    const overlays = Object.assign({}, this.state.overlays)
    if (!layers || !layers.length || !methods || !methods.length || methods.length !== layers.length) return
    let changes = {}

    changes = layers.reduce((acc, layer, idx) => {
      if (!layer) return acc
      if (isString(layer)) {
        layer = this.state.overlays[layer]
      }
      const style = this.getLayerFromStyle(layer.id) || {}
      if (methods[idx] === 'add') {
        style.layout = {
          ...style.layout,
          visibility: 'visible'
        }
        acc[layer.id] = {
          ...layer,
          style,
          layoutProps: {'visibility': 'visible'},
          layerName: layer.layerName || layer.name || layer.id,
          active: true
        }
      } else if (methods[idx] === 'remove') {
        acc[layer.id] = {
          ...layer,
          style: null,
          layoutProps: {'visibility': 'none'},
          hover: false,
          click: false,
          legendText: null,
          layerName: null,
          active: false
        }
      } else if (methods[idx] === 'toggleVisibility') {
        const oldVisibility = style.layout.visibility
        const newVisibility = oldVisibility === 'visible' ? 'none' : 'visible'
        style.layout = {
          ...style.layout,
          visibility: newVisibility
        }
        acc[layer.id] = {
          ...layer,
          style,
          layoutProps: {'visibility': newVisibility},
          active: true,
          layerName: layer.layerName || layer.name || layer.id
        }
      }
      return acc
    }, {})
    const newOverlays = Object.assign(overlays, changes)

    const cameraLayerIndex = layers.findIndex(l => !!l.camera);
    if (cameraLayerIndex !== -1 && methods[cameraLayerIndex] === 'add') {
      let camera = {
        ...this.state.camera,
        ...layers[cameraLayerIndex].camera
      }
      this.setCamera(camera)
    }

    const animationLayerIndex = layers.findIndex(l => !!l.animation);
    if (animationLayerIndex !== -1 && methods[animationLayerIndex] === 'add') {
      this.setAnimation(layers[animationLayerIndex].animation)
    } else {
      this.setAnimation(DEFAULT_ANIMATION_OPTIONS)
    }

    const movingMethod = layers.findIndex(l => !!l.movingMethod);
    if (movingMethod !== -1 && methods[movingMethod] === 'add') {
      this.setMovingMethod(layers[animationLayerIndex].movingMethod)
    } else {
      this.setMovingMethod(DEFAULT_MOVING_METHOD)
    }

    this.setState({
      overlays: newOverlays,
      activeLayerIds: Object.keys(newOverlays).filter(layerId => !!newOverlays[layerId].active),
    })
  }
  render() {
    return (
      <Grid fluid>
        <SplashModal
          backgroundImageUrl={this.props.affiliates}
          splash={this.props.splash}
        />
        <Row>
          <TopBar logout={this.props.logout}></TopBar>
          {!this.state.noMidBar && (
            <TransitionGroup component={null}>
              <CSSTransition classNames="menu-transition" timeout={300}>
                <Col className={'major-col'} md={SIDEBAR_WIDTH}>
                  <TransitionGroup component={null}>
                    {!this.state.midBarActive && (
                      <CSSTransition classNames="menu-transition" timeout={300}>
                        <Sidebar
                          logo={this.props.logo}
                          logoAlt={this.props.logoAlt}
                          disclaimer={this.props.disclaimer}
                          commentary={this.props.commentary}
                          helpIcon={this.props.helpIcon}
                          instructions={this.props.instructions}
                          forwardIcon={this.props.forwardIcon}
                          closeIcon={this.props.closeIcon}
                          categories={this.props.categories}
                          handleMidBarTopic={this.setMidBarTopic}
                          handleAreaViewer={this.setMidBarArea}
                          handleMemberViewer={this.setMidBarMembers}
                          helpButtonClick={this.resetMidBarToDefault}
                          closeButtonClick={this.toggleMidBar}
                        />
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                  <TransitionGroup component={null}>
                    {this.state.midBarActive && (
                      <CSSTransition classNames="menu-transition-ltr" timeout={300}>
                        <Midbar
                          midBarType={this.state.midBarType}
                          midBarData={this.state.midBarData}
                          mapStyle={this.state.mapStyle}
                          addMapLayer={(layer) => {
                            this.changeMapLayers([layer], ['add']);
                          }}
                          removeMapLayer={(layerId) => {
                            this.changeMapLayers([layerId], ['remove']);
                          }}
                          changeMapLayers={this.changeMapLayers}
                          setDocument={this.setDocument}
                          unsetDocument={this.unsetDocument}
                          hasLiveDocument={!!this.state.document}
                          activeLayers={this.state.activeLayerIds}
                          onBackClick={this.unsetMidBar}
                          onQuitClick={this.toggleMidBar}
                          instructions={this.props.instructions}
                        />
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                </Col>
              </CSSTransition>
            </TransitionGroup>
          )}
          <Col id="map-container" className={'major-col map-container'} md={this.state.noMidBar ? 12 : MAP_WIDTH}>
            <ContainerDimensions>
              {
                ({height, width, left, top, right, bottom}) => {
                  return (
                    <ReactMap
                      mapStyle={this.props.mapStyle}
                      mapApiKey={this.props.mapApiKey}
                      height={height}
                      width={width}
                      top={top}
                      left={left}
                      zoom={this.state.camera.zoom}
                      center={this.state.camera.center}
                      pitch={this.state.camera.pitch}
                      bearing={this.state.camera.bearing}
                      overlays={this.state.overlays}
                      geocoderProps={this.props.geocoderProps}
                      styleLoaded={({style}) => this.styleLoaded({style})}
                      closeIcon={this.props.closeIcon}
                      removeMapLayer={(layer) => {
                        this.changeMapLayers([layer], ['remove']);
                      }}
                      setCamera={this.setCamera}
                      animationOptions={this.state.animationOptions}
                      movingMethod={this.state.movingMethod}
                      toggleVisibility={(layerId) => this.changeMapLayers([layerId], ['toggleVisibility'])}
                      document={this.state.document}
                      setDocument={this.setDocument}
                    >
                      <div className="midbar-ctrl" style={{visibility: this.state.noMidBar ? 'visible' : 'hidden'}}>
                        <Button className="btn-menu-burger" onClick={this.toggleMidBar}>
                          <SVG src={this.props.burgerIcon} style={{fill: 'currentColor'}}/>
                        </Button>
                      </div>
                    </ReactMap>
                  )
                }
              }
            </ContainerDimensions>
          </Col>
        </Row>
      </Grid>
    );
  }
}
