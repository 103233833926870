import React, { Component } from 'react';
import { AutoSizer } from 'react-virtualized'
import { Line } from '@nivo/line';
import { Calendar } from '@nivo/calendar'
import { Bullet } from '@nivo/bullet'
import { Pie } from '@nivo/pie'

class NivoGraph extends Component {
  components = {
    Line: Line,
    Calendar: Calendar,
    Bullet: Bullet,
    Pie: Pie
  };
  render() {
    const TagName = this.components[this.props.nivoComponent || null];
    return TagName ? (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <TagName {...this.props.graphProps} data={this.props.data} height={height} width={width}/>
          )
        }}
      </AutoSizer>
    ) : null
  }
}

export default NivoGraph;
