import React, { Component } from 'react'
import LiveChat from 'react-livechat'

import {
  LOGO,
  HELP_ICON,
  CLOSE_ICON,
  BURGER_ICON,
  FORWARD_ICON,
  LOGO_ALT,
  DISCLAIMER
} from '../data/data.js'

import { MainLayout } from './MainLayout'

class Home extends Component {
  constructor(props, context) {
    super(props, context)
    this.livechat = React.createRef();
    this.state = {
      data: {}
    }
  }
  componentDidMount(){
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated()) {
      // If not logged in, go to root
      this.props.history.replace('/')
      return null
    }
    this.setState({
      data: this.props.auth.appData(),
      email: this.props.auth.userEmail() || '',
      name: this.props.auth.userName() || '',
    })
  }
  componentWillUnmount(){
    try {
      this.livechat.hide_chat_window();
    }
    catch(error) {
      console.error(error);
    }
  }
  render() {
    const basepath = process.env.REACT_APP_BASENAME
    const logo = basepath + LOGO
    const helpIcon = basepath + HELP_ICON
    const closeIcon = basepath + CLOSE_ICON
    const burgerIcon = basepath + BURGER_ICON
    const forwardIcon = basepath + FORWARD_ICON
    const { data, email, name } = this.state
    const affiliates = (data.AFFILIATE_LOGOS || []).map(logo => basepath + logo)
    return data.MAP_START ? (
      <div className="home-container">
        {data && <MainLayout
          logo={logo}
          logoAlt={LOGO_ALT}
          groupData={data}
          affiliates={affiliates}
          splash={data.SPLASH}
          closeIcon={closeIcon}
          burgerIcon={burgerIcon}
          disclaimer={DISCLAIMER}
          commentary={data.COMMENTARY}
          instructions={data.INSTRUCTIONS}
          helpIcon={helpIcon}
          forwardIcon={forwardIcon}
          categories={data.CATEGORIES}
          initialCamera={data.MAP_START}
          geocoderProps={data.GEOCODER_PROPS}
          mapStyle={data.MAPBOX_STYLE}
          mapApiKey={data.MAPBOX_APIKEY}
          logout={this.props.auth.logout}
        />}
        {email && <LiveChat
          license={parseInt(process.env.REACT_APP_LIVECHAT_LICENSE)}
          visitor={{name, email}}
          params={[{name: 'sports-eye-group', value: data.group}]}
          onChatLoaded={ref => this.livechat = ref}
        />}
      </div>
    ) : null;
  }
}

export default Home;
