// Accepts a Mapbox GL JS layer, and returns a React component that inclues the
// rendered legend
import React, { Component } from 'react'
import SVG from 'react-inlinesvg'
import { Button } from 'react-bootstrap'
import { sortableHandle } from 'react-sortable-hoc'

import Slider from './Slider';

import {
  FallbackLegend,
  CircleLegend, FillLegend, LineLegend, SymbolLegend
} from './LegendTypes'

import {
  EYE_OPEN_ICON, EYE_CLOSE_ICON, CLOSE_ICON, BURGER_ICON
} from '../../data/data.js'

const DragHandle = sortableHandle(() => <SVG src={BURGER_ICON} style={{fill: 'currentColor'}}/>)

const getLayerOpacityPaintProp = (layer) => {
  try {
    if (layer.type === 'circle') {
      return 'circle-opacity';
    } else if (layer.type === 'fill') {
      return 'fill-opacity';
    } else if (layer.type === 'line') {
      return 'line-opacity';
    } else if (layer.type === 'symbol') {
      return 'icon-opacity';
    } else if (layer.type === 'fill-extrusion') {
      return 'fill-extrusion-opacity';
    } else if (layer.type === 'heatmap') {
      return 'heatmap-opacity';
    } else if (layer.type === 'raster') {
      return 'raster-opacity';
    } else if (layer.type === 'background') {
      return 'background-opacity';
    } else if (layer.type === 'sky') {
      return 'sky-opacity';
    } else {
      // Other possible layer types:
       // - hillshade
      return null
    }
  } catch (error) {
    return null
  }
}

const getLayerOpacity = (layer) => {
  const opacityProp = getLayerOpacityPaintProp(layer);
  try {
    const opacity = layer['paint'] ? layer['paint'][opacityProp] : null
    return (typeof opacity === 'number') ? opacity : 1
  } catch (error) {
    return 1
  }
}

const graphic = ({props, width, height}) => {
  const layer = props.layer
  try {
    if (layer.type === 'circle') {
      return CircleLegend(width, height, props)
    } else if (layer.type === 'fill') {
      return FillLegend(width, height, props) || FallbackLegend(width, height, props)
    } else if (layer.type === 'line') {
      return LineLegend(width, height, props)
    } else if (layer.type === 'symbol') {
      return SymbolLegend(width, height, props)
    } else {
      // Other possible layer types:
       // - background
       // - raster
       // - fill-extrusion
       // - heatmap
       // - hillshade
       // - sky
      return FallbackLegend(width, height, props)
    }
  } catch (error) {
    console.debug({error})
    return FallbackLegend(width, height, props)
  }
}

class Legend extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opacity: getLayerOpacity(this.props.layer)
    }
  }
  removeLayer() {
    this.props.removeMapLayer()
  }
  toggleVisibility() {
    this.props.toggleVisibility()
  }
  setOpacity(value) {
    const paintProp = getLayerOpacityPaintProp(this.props.layer);
    const opacity = value / 100
    this.props.setPaintProperty(this.props.layer.id, paintProp, opacity)
    if (this.props.layer.layout['text-field'] !== undefined) {
      // Make text opaque too
      this.props.setPaintProperty(this.props.layer.id, 'text-opacity', opacity)
    }
    if (paintProp === 'cirle-opacity') {
      // Make stroke opaque in addition to the circle fill (doesn't apply to ordinary fill)
      this.props.setPaintProperty(this.props.layer.id, 'circle-stroke-opacity', opacity)
    }
    this.setState({opacity})
  }
  render() {
    if (!this.props.layer) return null
    const height = 58 // this.props.height
    const width = this.props.width * 0.8
    const opacity = this.state.opacity * 100
    const isVisible = this.props.layer.layout.visibility === 'visible'
    return (
      <div className="legend-graphic-container">
        <div className="column-main" style={{filter: !isVisible ? "blur(1px)" : "initial"}}>
          {graphic({props: this.props, width, height})}
          {isVisible && (<div style={{width:'100%', paddingRight: 15}}>
            <Slider label="Opacity" options={[0,20,40,60,80,100]} value={opacity} setValue={this.setOpacity.bind(this)} />
          </div>)}
        </div>
        <div className="column-side legend-controls-container">
          <div className="legend-controls-container-inner">
            <Button onClick={this.removeLayer.bind(this)}>
              <SVG src={CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
          <div className="legend-controls-container-inner">
            <Button onClick={this.toggleVisibility.bind(this)}>
              <SVG src={isVisible ? EYE_OPEN_ICON : EYE_CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
          {this.props.handle && (
            <div className="legend-controls-container-inner">
              <Button className="draggable">
                <DragHandle/>
              </Button>
            </div>
          )}
        </div>
      </div>
    )
   }
}
export default Legend;
