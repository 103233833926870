import { GROUPS_IN_PRIORITY_ORDER } from './data/data.js'

const NAMESPACE = "https://prototype.cartisanpublishing.com"

export const getGroup = (idPayload) => {
  const prop = `${NAMESPACE}/groups`
  const groups = idPayload[prop] || []
  for (let i = GROUPS_IN_PRIORITY_ORDER.length; i--;){
    let group = GROUPS_IN_PRIORITY_ORDER[i]
    if (groups.includes(group)) {
      return group
    }
  }
  return 'Default'
}

export const getUser = (idPayload) => {
  return idPayload[`${NAMESPACE}/user`] || {}
}

export const getAppData = (groupId, accessToken) => {
  let dataUrl = `${process.env.REACT_APP_GROUPS_URI}?groupId=${groupId}`
  let groupsUrl = new URL(process.env.REACT_APP_GROUPS_URI)
  if (groupsUrl.hostname !== 'localhost') {
    dataUrl = `${dataUrl}&jwt=${accessToken}`
  }
  return fetch(dataUrl).then(response => {
    return response.json()
  }).then(appData => {
    return appData.Item ?
      appData.Item : // lambda
      appData // local json-server
  })
}
