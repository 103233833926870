const Slider = ({label, value, setValue, min=0, max=100, step=1}) => {
  return (
    <div className="legend-slider-container">
      <label htmlFor="slider">{label}</label>
      <input
        type="range"
        id={label}
        name={label}
        min={min}
        max={max}
        step={step}
        value={value}
        list={"ticks"}
        onChange={e => setValue && setValue(parseInt(e.target.value))}
      />
    </div>
  )
}

export default Slider;
