import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import Legend from  './Legend';

const SortableLegendContainer = sortableContainer(({ children }) => {
  return (
    <div className="map-element-container-outer legend-container-outer">
      {children}
    </div>
  )
})


const SortableLegend = sortableElement(({value}) => {
  return (
    <div className="legend-container-inner">
      <ContainerDimensions>
        {value}
      </ContainerDimensions>
    </div>
  )
})

export default function LegendContainer({overlays, reorderMapLayer, removeMapLayer, toggleVisibility, setPaintProperty, containerRef}) {
  return (
    <SortableLegendContainer
      onSortEnd={reorderMapLayer}
      useDragHandle={true}
      helperContainer={containerRef.current}
    >
      {overlays.map((overlay, index) => {
        const style = overlay.style
        if (!style) return null
        let legend = (
          <Legend
            layer={style}
            handle
            legendText={overlay.legendText}
            removeMapLayer={() => removeMapLayer(overlay.id)}
            toggleVisibility={() => toggleVisibility(overlay.id)}
            setPaintProperty={setPaintProperty}
          />
        )
        return (<SortableLegend
          key={`${overlay.id}-${style['source-layer']}`}
          index={index}
          value={legend}
        />)
      })}
    </SortableLegendContainer>
  )
}
