import React, { Component } from 'react';
import { Tab, Nav, NavItem, Col, Row } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import NivoComponent from './NivoGraph'

class DocumentComponent extends Component {
  render() {
    let childComponent = null
    if (!!this.props.nivoComponent) {
      childComponent = (<NivoComponent nivoComponent={this.props.nivoComponent} data={this.props.data} graphProps={this.props.props}/>)
    } else if (!!this.props.html) {
      childComponent = (<div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>)
    } else if (this.props.components) {
     // Nested
     childComponent = this.props.components.map((childProps, i) => {
       return (
         <DocumentComponent key={i} {...childProps}/>
       )
     })
    }
    return (
      <div style={{...this.props.style, height: '100%'}}>
        { childComponent }
      </div>
    )
  }
}


class TabbableDocumentComponent extends Component {
  render() {
    let documents = this.props.documents
    return !!documents && Array.isArray(documents) && (
      <div id="notice-modal" className="notice-modal modal-container">
        <Tab.Container defaultActiveKey={0}>
          <Col>
            <Row className="modal-tabs-container">
              <Col className="modal-tabs" md={11}>
                <Nav bsStyle="tabs" justified={false} stacked={false}>
                  {documents.map((document, idx) => (
                    <NavItem key={idx} eventKey={idx}>{document.label ? document.label : document.nivoComponent ? 'Graph' : document.html ? 'Report' : 'Document'}</NavItem>
                  ))}
                </Nav>
              </Col>
              <Col className="modal-actions" md={1}>
                <Button onClick={this.props.onClose}>
                  <SVG src={this.props.closeIcon}/>
                </Button>
              </Col>
            </Row>
            <Row className="modal-content-container">
              <Tab.Content animation={false}>
                {documents.map((document, idx) => (
                  <Tab.Pane key={idx} eventKey={idx}>
                    <section className="notice-modal-body">
                      <DocumentComponent {...document} />
                    </section>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Row>
          </Col>
        </Tab.Container>
      </div>
    )
  }
}

export default TabbableDocumentComponent;
